import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import TabItem from './TabItem';

import {
  greenishcyan,
  veryLightBluishGray,
  veryLightPink,
  breakpoints,
} from '../../css-utilities';

// ActiveComponent is passed as a key
const CardWithTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  // show active tabs component, only one component should be returned
  const ActiveTabComponent = useCallback(() => {
    return tabs.map(({ id, component: ChildComponent, props }) =>
      id === activeTab ? <ChildComponent {...props} key={id} /> : null
    );
  }, [tabs, activeTab]);

  // render TabItems components
  const renderTabs = tabs.map(({ id, name }) => (
    <TabItem
      activeTab={activeTab}
      id={id}
      key={`${name}-${id}`}
      name={name}
      setActiveTab={setActiveTab}
    />
  ));

  return (
    <>
      <div className="tabs-container">{renderTabs}</div>
      <div className="flex wrap active-tab">
        <ActiveTabComponent />
      </div>

      <style jsx>
        {`
          .tabs-container {
            display: flex;
            flex-wrap: wrap;
            margin: 20px 0 30px 0;
          }
          .name {
            font-size: inherit;
          }

          .active-tab {
            background-color: ${veryLightBluishGray};
            padding: 10px;
          }

          span {
            cursor: pointer;
            position: relative;
            margin-right: 40px;
            text-transform: uppercase;
            padding-bottom: 30px;
            font-size: 15px;
          }

          span:not(:first-child) {
            margin-left: 40px;
          }

          span:not(:last-child)::before {
            content: '';
            position: absolute;
            display: block;
            height: 75%;
            width: 1px;
            background: ${veryLightPink};
            right: -40px;
            top: 7px;
          }

          span.active::after {
            content: '';
            display: block;
            position: relative;
            background: ${greenishcyan};
            width: 90%;
            height: 2px;
            top: 0;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .active-tab {
              padding: 60px 30px;
            }

            span {
              font-size: 20px;
            }
          }
        `}
      </style>
    </>
  );
};

CardWithTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      component: PropTypes.func,
      props: PropTypes.object,
    })
  ).isRequired,
};

export default CardWithTabs;
