import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';

import Link from '../../Link';
import { slugLowerCase } from '../../../lib/utils';
import {
  veryLightPink,
  darkishPink,
  greyishBrown,
} from '../../../css-utilities';

const linkStyle = css.resolve`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  max-width: 350px;
  text-decoration: none;
  border-bottom: 1px solid ${veryLightPink};

  :focus,
  :hover {
    background-color: #e8eaec;
    transition: all 0.5s;
  }

  :focus :global(.arrow),
  :hover :global(.arrow) {
    transform: translateX(5px);
  }
`;

const SidebarItem = ({
  course,
  id,
  title,
  courseLeaders,
  discount,
  availableSeats,
  isOpen,
}) => (
  <>
    {availableSeats != 0 ? (
      <Link
        className={linkStyle.className}
        tabIndex={isOpen === true ? '' : '-1'}
        to={`/utbildningar/${slugLowerCase(course)}/${id.substring(22, 28)}`}
      >
        <div className="content">
          <span className="title">{title}</span>
          {discount ? (
            <span className="discount">{`-${discount}%`}</span>
          ) : null}
          <span className="text">{`Kursledare: ${courseLeaders}`}</span>

          {availableSeats > 0 && availableSeats < 5 ? (
            <span className="discount">
              {`Endast ${availableSeats} plats${
                availableSeats === 1 ? '' : 'er'
              } kvar!`}
            </span>
          ) : null}
        </div>
        <i className="arrow fas fa-long-arrow-alt-right" />
      </Link>
    ) : (
      <div className={linkStyle.className}>
        <div className="content noSeats">
          <span className="title">{title}</span>
          <span className="text">Fullbokad</span>
        </div>
      </div>
    )}

    {linkStyle.styles}

    <style jsx>
      {`
        .noSeats {
          color: grey;
        }

        .content {
          margin-right: 20px;
        }
        .content.noSeats .title,
        .content.noSeats .text,
        .content.noSeats .discount {
          color: ${veryLightPink};
        }

        .arrow {
          font-size: 18px;
          transition: all 0.5s;
        }

        .title {
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;
          color: #000;
        }

        .discount {
          font-size: 12px;
          color: ${darkishPink};
          font-weight: bold;
        }

        .text {
          display: block;
          padding: 4px 0;
          font-size: 14px;
          font-weight: 300;
          color: ${greyishBrown};
        }
      `}
    </style>
  </>
);

SidebarItem.defaultProps = {
  discount: null,
};

SidebarItem.propTypes = {
  availableSeats: PropTypes.number.isRequired,
  course: PropTypes.string.isRequired,
  courseLeaders: PropTypes.string.isRequired,
  discount: PropTypes.number,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default SidebarItem;
