import React from 'react';
import PropTypes from 'prop-types';

import { sortDateTime } from '../../../lib/utils';
import OccasionPresentational from './Occasion';

const Occasion = ({ occasion, course, style, ...props }) => {
  // Course leaders as a string
  const courseLeadersString = occasion.courseLeaders
    .map(leader => leader.firstname + ' ' + leader.lastname)
    .join(', ');

  const { title, numberOfDays, firstDay, lastDay } = sortDateTime(
    occasion.allDates
  );

  return (
    <OccasionPresentational
      course={course}
      style={style}
      {...occasion}
      courseLeaders={courseLeadersString}
      firstDay={firstDay}
      lastDay={lastDay}
      numberOfDays={numberOfDays}
      title={title}
      {...props}
    />
  );
};

Occasion.defaultProps = {
  style: {},
};

Occasion.propTypes = {
  course: PropTypes.string.isRequired,
  occasion: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default Occasion;
