import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CourseDetails from '../components/CourseDetails';
import Document from '../components/Document';
import Banner from '../components/Banner';
import Section from '../components/Section';
import Column from '../components/Column';
import PriorKnowledgeSection from '../components/pageSections/PriorKnowledgeSection';
import Tabs from '../components/Tabs';
import CourseContentCard from '../components/CourseContentCard';
import CoursePlanCard from '../components/CoursePlanCard/CoursePlanCard';
import CollapsableSidebar from '../components/CollapsableSidebar';
import CourseOccasions from '../components/CourseOccasions';
import ActionCard from '../components/ActionCard';
import IntroSection from '../components/pageSections/IntroSection';
import CourseLeadersSection from '../components/pageSections/CourseLeadersSection';
import { dateIsTodayOrInTheFuture, firstDay } from '../lib/utils';

const CoursePage = ({
  data: {
    datoCmsCourse: {
      seo,
      occasions,
      name,
      tagline,
      bannerImage,
      descriptionTitle,
      description,
      courseImage,
      price,
      location,
      numberOfDays,
      length,
      basePriorKnowledge,
      meritoriousPriorKnowledge,
      priorKnowledgeImage,
      courseInformationHeader,
      courseContent,
      schedule,
      coursePlanIntro,
      coursePlan,
      actionCard,
    },
  },
}) => {
  const filteredOccasions = occasions.filter(({ allDates }) => {
    const date = firstDay(allDates);
    return dateIsTodayOrInTheFuture(date);
  });

  const firstOccasionLocation = filteredOccasions[0]
    ? filteredOccasions[0].location
    : location;

  const filteredCourseLeaders = filteredOccasions.reduce((acc, occasion) => {
    occasion.courseLeaders.forEach(leader => {
      acc[leader.id] = acc[leader.id] || leader;
    });
    return acc;
  }, {});

  const allCourseLeaders = Object.keys(filteredCourseLeaders).map(
    key => filteredCourseLeaders[key]
  );

  return (
    <Document seo={seo}>
      <Banner bgImage={bannerImage} header={name} punchline={tagline} />
      {filteredOccasions.length && (
        <CollapsableSidebar course={name} occasions={filteredOccasions} />
      )}

      <IntroSection
        image={courseImage}
        text={description}
        title={descriptionTitle}
      >
        <CourseDetails
          length={length}
          location={firstOccasionLocation}
          numberOfDays={numberOfDays}
          price={price}
        />
      </IntroSection>

      <PriorKnowledgeSection
        base={basePriorKnowledge}
        image={priorKnowledgeImage}
        meritorious={meritoriousPriorKnowledge}
      />

      <Section>
        <h3>{courseInformationHeader}</h3>
        <Column width="100">
          <Tabs
            tabs={[
              {
                name: 'Kursinnehåll',
                id: 0,
                component: CourseContentCard,
                props: { courseContent, schedule },
              },
              {
                name: 'Kursplan',
                id: 1,
                component: CoursePlanCard,
                props: { coursePlanIntro, coursePlan },
              },
            ]}
          />
        </Column>
      </Section>

      <CourseLeadersSection courseLeaders={allCourseLeaders} />

      {filteredOccasions.length ? (
        <Section>
          <h3 className="course-occasions">Kurstillfällen</h3>
          <div className="flex wrap course-occasions" id="kurser">
            <CourseOccasions course={name} occasions={filteredOccasions} />
          </div>
        </Section>
      ) : null}

      <ActionCard {...actionCard} />

      <style jsx>
        {`
          .course-occasions {
            width: 100%;
          }
        `}
      </style>
    </Document>
  );
};

CoursePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query coursePageQuery($courseId: String!) {
    datoCmsCourse(id: { eq: $courseId }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      occasions: courseOccasions {
        id
        text
        location
        discount
        seats
        availableSeats
        allDates {
          datetime
        }
        courseLeaders {
          id
          firstname
          lastname
          bio
          image {
            url
          }
        }
      }
      name
      tagline
      bannerImage {
        url
      }
      descriptionTitle
      description
      courseImage {
        url
      }
      price
      location
      numberOfDays
      length
      basePriorKnowledge {
        name
        id
      }
      meritoriousPriorKnowledge {
        name
        id
      }
      priorKnowledgeImage {
        url
      }
      courseInformationHeader
      courseContent
      schedule
      coursePlanIntro
      coursePlan {
        text
        id
      }
      actionCard {
        title
        text
        image {
          url
        }
        btnTitle
        path
      }
    }
  }
`;

CoursePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CoursePage;
