import React from 'react';
import PropTypes from 'prop-types';

import ItemPresentantational from './Item';
import { courseLeadersString, sortDateTime } from '../../../lib/utils';

const Item = ({ allDates, courseLeaders, ...props }) => {
  let { title } = sortDateTime(allDates);
  let text = courseLeadersString(courseLeaders);

  return (
    <ItemPresentantational {...props} courseLeaders={text} title={title} />
  );
};

Item.propTypes = {
  allDates: PropTypes.array.isRequired,
  courseLeaders: PropTypes.array.isRequired,
};

export default Item;
