import React from 'react';
import PropTypes from 'prop-types';

import Section from '../Section';
import LinkButton from '../LinkButton';
import { slugLowerCase } from '../../lib/utils';
import { veryLightBluishGray, breakpoints } from '../../css-utilities';

const ActionCard = ({ title, text, image, btnTitle, path }) => (
  <Section background={veryLightBluishGray}>
    <div className="action__content">
      <h4>{title}</h4>
      <p>{text}</p>
      <LinkButton
        arrow="right"
        dark
        label={btnTitle}
        to={`/${slugLowerCase(path)}`}
      />
    </div>
    <div className="action_img">
      <img alt="action-illustration" src={image.url} />
    </div>

    <style jsx>
      {`
        .action__content {
          margin: 0 30px;
          order: 2;
        }
        .action_img {
          margin: 0 auto;
          order: 1;
          max-height: 375px;
          max-width: 375px;
        }
        img {
          height: auto;
        }
        @media (min-width: ${breakpoints.desktopSM}) {
          .action__content {
            width: calc(63% - 40px);
            order: 1;
            margin: 0 0 0 40px;
            padding-right: 30px;
          }
          .action_img {
            max-height: initial;
            max-width: initial;
            width: calc(37% - 40px);
            margin: 0 40px 0 0;
          }
          img {
            height: auto;
          }
        }
      `}
    </style>
  </Section>
);

ActionCard.defaultProps = {
  image: null,
  path: null,
};

ActionCard.propTypes = {
  btnTitle: PropTypes.string.isRequired,
  image: PropTypes.object,
  path: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ActionCard;
