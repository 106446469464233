import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useClickedOutside from '../../lib/hooks/useClickedOutside';
import Item from './Item';
import { boxShadow } from '../../styles';

import { bluishPurple, veryLightBluishGray } from '../../css-utilities';

const CollapsableSidebar = ({
  occasions,
  course,
  isOpen,
  setOpen,
  handleCloseSidebar,
}) => {
  const sidebarRef = useRef(null);
  useClickedOutside(sidebarRef, handleCloseSidebar);

  const renderOccasions = occasions.map(occasion => (
    <Item course={course} {...occasion} isOpen={isOpen} key={occasion.id} />
  ));

  return (
    <aside
      className={`course-side-bar ${isOpen ? '' : 'closed'}`}
      ref={sidebarRef}
    >
      <button className="side-bar-button" onClick={setOpen}>
        Boka nu
      </button>

      <div className="occasions">
        <h2 className="title">{`${course} • Kurstillfällen`}</h2>
        {renderOccasions}
      </div>

      <style jsx>
        {`
          .course-side-bar {
            appearance: none;
            position: relative;
            height: 100vh;
            width: 350px;
            max-width: calc(100% - 70px);
            position: fixed;
            z-index: 9999;
            top: 0;
            right: 0;
            z-index: 15;
            background-color: ${veryLightBluishGray};
            transition: all 0.5s ease;
            box-shadow: ${boxShadow};
          }

          .course-side-bar.closed {
            right: -350px;
            max-width: 350px;
          }
          .side-bar-button {
            position: absolute;
            top: 50%;
            left: -85px;
            padding: 15px 30px;
            font-family: 'Poppins';
            background-color: ${bluishPurple};
            transform: rotate(-90deg);
            color: white;
            cursor: pointer;
            border: none;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }

          .occasions {
            height: 100%;
            overflow: auto;
          }

          .title {
            font-size: 15px;
            font-weight: bold;
            color: white;
            background-color: ${bluishPurple};
            margin: 0;
            padding: 10px 10px 10px 18px;
          }
        `}
      </style>
    </aside>
  );
};

CollapsableSidebar.propTypes = {
  course: PropTypes.string.isRequired,
  handleCloseSidebar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  occasions: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CollapsableSidebar;
