import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import css from 'styled-jsx/css';

import { slugLowerCase } from '../../../lib/utils';
import {
  darkishPink,
  veryLightPink,
  greyishBrown,
  black,
  breakpoints,
} from '../../../css-utilities';

const { className, styles } = css.resolve`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-grow: 2;
  position: relative;
  width: 100%;
  padding: 20px 20px;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid ${veryLightPink};
  transition: 0.3s background ease;
  
  :hover {
    background: #e9f2fb;
  }

  :first-child {
    border-top: 1px solid ${veryLightPink};
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 30px 20px;
    flex-wrap: nowrap;
  }
`;

const CourseOccasion = ({
  id,
  discount,
  availableSeats,
  course,
  style,
  title,
  courseLeaders,
}) => {
  const courseName = slugLowerCase(course);
  const courseOccasion = id.substring(22, 28);
  const courseOccasionHref = `/utbildningar/${courseName}/${courseOccasion}`;

  return (
    //bryta ut
    <>
      {availableSeats ? (
        <Link className={className} style={style} to={courseOccasionHref}>
          <div className="text">
            <span className="date">
              {title}
              {discount ? (
                <span className="discount percentage">{` -${discount}%`}</span>
              ) : null}
            </span>

            <span className="kursledare">{`Kursledare: ${courseLeaders}`}</span>

            {availableSeats < 5 ? (
              <span className="availableSeats">
                {`Endast ${availableSeats} ${
                  availableSeats > 1 ? 'platser' : 'plats'
                }
             kvar!`}
              </span>
            ) : null}
          </div>
          <span className="btn">
            Boka
            <i className="fas arrow fa-long-arrow-alt-right" />
          </span>
        </Link>
      ) : (
        <div className={`fullbokad ${className}`} style={style}>
          <div>
            <span className="date">{title}</span>
            <span className="kursledare">{`Kursledare: ${courseLeaders}`}</span>
          </div>
          <span className="no-seats">Fullbokad</span>
        </div>
      )}

      {styles}

      <style jsx>
        {`
          .text {
            width: calc(100% - 60px);
            padding-bottom: 20px;
            flex-shrink: 1;
            text-overflow: ellipsis;
          }
          .kursledare {
            display: none;
            width: 100%;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.56;
            color: ${greyishBrown};
            text-overflow: ellipsis;
          }

          .date {
            font-weight: bold;
            font-family: 'Poppins';
            font-size: 18px;
            margin: 0;
            color: ${availableSeats ? black : veryLightPink};
          }

          .discount {
            position: absolute;
            top: 21px;
            right: 20px;
            font-size: 15px;
            color: ${darkishPink};
            font-weight: bold;
          }

          .availableSeats {
            display: block;
            font-size: 15px;
            color: ${darkishPink};
            font-weight: bold;
          }

          .fullbokad .date,
          .fullbokad .kursledare {
            color: ${veryLightPink};
          }

          .no-seats {
            font-family: 'Open Sans';
            font-size: 14px;
            color: ${veryLightPink};
            font-weight: bold;
            text-transform: uppercase;
            margin-right: 20px;
          }

          @media (min-width: 460px) {
            .item {
              padding-right: 20px;
            }
            .kursledare {
              display: block;
            }
          }

          @media (min-width: ${breakpoints.tablet}) {
            .text {
              width: auto;
              padding: 0;
            }
            .discount {
              position: relative;
              top: -1px;
              left: 5px;
            }
          }

          @media (min-width: ${breakpoints.desktopSM}) {
            .discount {
              left: 10px;
            }
          }
        `}
      </style>
    </>
  );
};

CourseOccasion.defaultProps = {
  discount: null,
  style: {},
};

CourseOccasion.propTypes = {
  availableSeats: PropTypes.number.isRequired,
  course: PropTypes.string.isRequired,
  courseLeaders: PropTypes.string.isRequired,
  discount: PropTypes.number,
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default CourseOccasion;
