import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrencySEK } from '../../lib/utils';
import {
  greenishcyan,
  sunFlowerYellow,
  darkishPink,
} from '../../css-utilities';

const CourseDetails = ({ length, location, price, numberOfDays }) => (
  <div className="course-details">
    <div>
      <i className="fas fa-coins" style={{ color: sunFlowerYellow }} />
      <span className="info-label">Pris: </span>
    </div>
    <span className="value">{formatCurrencySEK(price)}</span>

    <div>
      <i className="fas fa-stopwatch" style={{ color: darkishPink }} />
      <span className="info-label">Längd:</span>
    </div>
    <span className="value">
      {`${length}h`}
      {numberOfDays !== null &&
        numberOfDays <= 1 &&
        `, under ${numberOfDays} dag`}
      {numberOfDays !== null &&
        numberOfDays > 1 &&
        `, under ${numberOfDays} dagar`}
    </span>

    <div>
      <i
        className="fas fa-map-pin"
        style={{ color: greenishcyan, position: 'relative', left: 3 }}
      />
      <span className="info-label">Plats: </span>
    </div>
    <span className="value">{location}</span>

    <style jsx>
      {`
        .course-details {
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-row-gap: 20px;
          font-size: 18px;
        }

        .info-label {
          font-weight: bold;
          font-style: italic;
        }

        .course-details .details {
          margin-right: 35px;
        }

        .course-details i {
          margin-right: 20px;
          font-size: 21px;
        }

        .value {
          margin-left: 15px;
        }
      `}
    </style>
  </div>
);

CourseDetails.defaultProps = {
  numberOfDays: null,
};

CourseDetails.propTypes = {
  length: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  numberOfDays: PropTypes.number,
  price: PropTypes.number.isRequired,
};

export default CourseDetails;
