import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import CollapsableSidebar from './CollapsableSidebar';

const CollapsableSidebarWrapper = ({ occasions, course }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCloseSidebar = useCallback(() => {
    setIsOpen(false);
  });

  return (
    <CollapsableSidebar
      course={course}
      handleCloseSidebar={handleCloseSidebar}
      isOpen={isOpen}
      occasions={occasions}
      setOpen={handleToggleOpen}
    />
  );
};

CollapsableSidebarWrapper.propTypes = {
  course: PropTypes.string.isRequired,
  occasions: PropTypes.array.isRequired,
};

export default CollapsableSidebarWrapper;
