import React from 'react';
import PropTypes from 'prop-types';

import { breakpoints } from '../../css-utilities';

const CourseLeader = ({ firstname, lastname, bio, image }) => (
  <div className="course-leader">
    <img alt="teacher" src={image.url} />
    <div className="text">
      <h4>{`${firstname} ${lastname}`}</h4>
      <p>{bio}</p>
    </div>
    <style jsx>
      {`
        .course-leader {
          display: flex;
          align-items: flex-start;

          flex-direction: column;
          margin-bottom: 50px;
        }

        .course-leader:last-child {
          margin-bottom: 0;
        }

        img {
          width: 100%;
          max-width: 400px;
          margin-bottom: 0;
          margin: 0 auto;
        }
        .text {
          flex: 1;
        }

        h4 {
          margin: 20px 0 10px 0;
        }
        p {
          margin: 0;
        }

        @media (min-width: ${breakpoints.desktopSM}) {
          .course-leader {
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
          }
          img {
            width: 200px;
            margin-right: 30px;
          }
          h4 {
            margin: 0 0 20px;
          }
        }
      `}
    </style>
  </div>
);

CourseLeader.propTypes = {
  bio: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  lastname: PropTypes.string.isRequired,
};

export default CourseLeader;
