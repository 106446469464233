import React from 'react';
import PropTypes from 'prop-types';

import Section from '../../Section';
import { breakpoints } from '../../../css-utilities';

const IntroSection = ({ title, text, image, children }) => (
  <Section background="#fff" skew="top">
    <div className="intro__wrapper">
      <div className="text">
        <h2>{title}</h2>
        <p>{text}</p>
        {children}
      </div>
      <div className="image__wrapper">
        <img alt="intro" src={image.url} />
      </div>
    </div>

    <style jsx>
      {`
        h2 {
          margin: 0 0 10px;
        }
        .intro__wrapper {
          align-items: center;
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 40px;
        }

        .text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        img {
          max-width: 100%;
        }

        @media (min-width: ${breakpoints.tablet}) {
          .intro__wrapper {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 40px;
          }
        }
      `}
    </style>
  </Section>
);

IntroSection.defaultProps = {
  image: null,
};

IntroSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  image: PropTypes.object,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default IntroSection;
