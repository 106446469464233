import React from 'react';
import PropTypes from 'prop-types';

import { purpleLinearGradient, breakpoints } from '../../css-utilities';

const BackgroundImage = ({ url, overlay }) => (
  <div className="background-img">
    {overlay ? <div className="overlay" /> : null}

    <style jsx>
      {`
        .background-img {
          background-image: ${`url(${url})`};
          background-size: cover;
          position: relative;
          min-height: 300px;
          width: 100%;
        }
        @media (min-width: ${breakpoints.tablet}) {
          .background-img {
            height: 100%;
            margin-top: 0;
          }
        }

        .overlay {
          background: ${purpleLinearGradient};
          opacity: 0.5;
          position: absolute;
          width: 100%;
          height: 100%;
        }
      `}
    </style>
  </div>
);

BackgroundImage.defaultProps = {
  overlay: false,
};

BackgroundImage.propTypes = {
  overlay: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

export default BackgroundImage;
