import React from 'react';
import PropTypes from 'prop-types';

import { SectionFullWidth } from '../../Section';
import BackgroundImage from '../../BackgroundImage';
import {
  veryLightBluishGray,
  greyishBrown,
  breakpoints,
  purpleLinearGradient,
} from '../../../css-utilities';

const PriorKnowledgeSection = ({ base, image, meritorious }) => {
  const renderPriorKnowledge = list =>
    list.map(({ name, id }) => <li key={id}>{name}</li>);

  return (
    <SectionFullWidth
      background={veryLightBluishGray}
      className="skew--top-left skew--bottom-left"
      noPadding
    >
      <div className="prior-knowledge">
        <h3 size="m">Förkunskaper</h3>
        <span className="sub">Goda kunskaper krävs inom:</span>
        <ul>{renderPriorKnowledge(base)}</ul>
        <span className="sub">Meriterande kunskaper inom:</span>
        <ul>{renderPriorKnowledge(meritorious)}</ul>
      </div>
      <div className="bg-img-wrapper">
        <BackgroundImage
          overlay
          url={image ? image.url : purpleLinearGradient}
        />
      </div>

      <style jsx>
        {`
          li {
            list-style-type: none;
            font-family: 'Lato';
            font-size: 18px;
            font-weight: 300;
            line-height: 1.56;
            color: ${greyishBrown};
          }

          .prior-knowledge {
            align-self: center;
            grid-column: 2 / 3;
            padding: 60px 0;
          }

          ul {
            margin-top: 0;
            line-height: 1.5;
            padding-left: 20px;
          }

          li {
            list-style-type: disc;
          }

          .sub {
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
            color: ${greyishBrown};
          }

          .bg-img-wrapper {
            display: none;
            height: 500px;
            grid-column: 3 / 5;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .bg-img-wrapper {
              display: block;
            }
            .prior-knowledge {
              padding: 0;
            }
          }
        `}
      </style>
    </SectionFullWidth>
  );
};

PriorKnowledgeSection.defaultProps = {
  base: [],
  image: null,
  meritorious: [],
};

PriorKnowledgeSection.propTypes = {
  base: PropTypes.array,
  image: PropTypes.object,
  meritorious: PropTypes.array,
};

export default PriorKnowledgeSection;
