import { format, isToday, isBefore } from 'date-fns';
import sv from 'date-fns/locale/sv';
import slugify from 'slugify';

export const dateIsTodayOrInTheFuture = date =>
  isBefore(new Date(), date) || isToday(date);

export const dateToString = date =>
  format(date, 'D MMMM YYYY', {
    locale: sv,
  });

export const CustomDateToString = (date, dateFormat) => {
  return format(date, dateFormat, {
    locale: sv,
  });
};

export const slugLowerCase = string => {
  return slugify(`/${string}`, { lower: true });
};

const SEKFormater = new Intl.NumberFormat('sv', {
  style: 'currency',
  currency: 'SEK',
  currencyDisplay: 'code',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

const SEKFormaterFloat = new Intl.NumberFormat('sv', {
  style: 'currency',
  currency: 'SEK',
  currencyDisplay: 'code',
  maximumFractionDigits: 2,
}).format;

export const formatCurrencySEK = number =>
  number % 1 === 0 ? SEKFormater(number) : SEKFormaterFloat(number);

export const createAttendee = id => ({
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  active: true,
  id,
});
// buyer extends attendee object but does
// not need an id since there is only one buyer
// passing string "buyer" along as id
export const createBuyer = () =>
  Object.assign(
    {
      company: null,
      companyRegistrationNumber: null,
      address1: null,
      address2: null,
      zipCode: null,
      area: null,
    },
    createAttendee('buyer')
  );

export const debounceEvent = (fn, wait = 200) => {
  let timeout;
  return event => {
    event.persist();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(event);
    }, wait);
  };
};

// ImmediateCallFn will be called everytime
export const debounceEventWithImmediateCall = (
  fn,
  wait = 200,
  ImmediateCallFn
) => {
  let timeout;
  return event => {
    ImmediateCallFn();
    event.persist();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(event);
    }, wait);
  };
};

export const sortedDates = dates => {
  return dates.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
};

export const firstDay = dates => {
  const sorted = sortedDates(dates);
  return sorted[0].datetime;
};

export const lastDay = dates => {
  const sorted = sortedDates(dates);
  return sorted[sorted.length - 1].datetime;
};

export const sortDateTime = dates => {
  // Sort dates first to last
  let sorted = sortedDates(dates);

  let firstDay = sorted[0].datetime;
  let lastDay = sorted[sorted.length - 1].datetime;

  // Find unique days
  let sortedDaysIntoKeys = sorted.reduce((acc, cur) => {
    let day = cur.datetime.substring(0, 10);
    acc[day] = acc[day] | day;
    return acc;
  }, {});
  let numberOfDays = Object.keys(sortedDaysIntoKeys).length;

  // Create String
  let title = '';

  // if dates are not the same year
  if (firstDay.substring(0, 4) != lastDay.substring(0, 4)) {
    title += CustomDateToString(firstDay, 'D MMM YYYY - ');
    title += CustomDateToString(lastDay, 'D MMM YYYY');
  }
  // if dates not the same month
  else if (firstDay.substring(6, 8) != lastDay.substring(6, 8)) {
    title += CustomDateToString(firstDay, 'D MMM - ');
    title += CustomDateToString(lastDay, 'D MMM YYYY');
  }
  // if dates are not the same day
  else if (firstDay.substring(8, 10) != lastDay.substring(8, 10)) {
    title += CustomDateToString(firstDay, 'D - ');
    title += CustomDateToString(lastDay, 'D MMM YYYY');
  }
  // if dates are the same
  else {
    title += CustomDateToString(firstDay, 'D MMM YYYY');
  }

  title += numberOfDays > 1 ? ` • ${numberOfDays} dagar` : '';

  return {
    title,
    numberOfDays,
    sortedDaysIntoKeys,
    firstDay,
    lastDay,
    sorted,
  };
};

export const courseLeadersString = courseLeaders =>
  courseLeaders
    .map(leader => leader.firstname + ' ' + leader.lastname)
    .join(', ');
