import React from 'react';
import PropTypes from 'prop-types';

import Markdown from '../Markdown';

import { breakpoints } from '../../css-utilities';

const CourseContentCard = ({ courseContent, schedule }) => {
  return (
    <section>
      <div className="flex wrap course-content__card">
        <div className="course-content-column">
          <Markdown source={courseContent} />
        </div>

        <div className="course-content-column">
          <div className="course-content-column-inner">
            <Markdown source={schedule} />
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .course-content__card {
            justify-content: space-between;
          }
          .course-content-column {
            width: 100%;
          }

          .course-content-column-inner {
            padding: 10px 20px 20px 20px;
            background: white;
          }

          @media (min-width: ${breakpoints.desktopSM}) {
            .course-content-column {
              width: 48%;
            }

            .course-content-column-inner {
              padding: 30px 40px;
            }
          }
        `}
      </style>
    </section>
  );
};

CourseContentCard.propTypes = {
  courseContent: PropTypes.string.isRequired,
  schedule: PropTypes.string.isRequired,
};

export default CourseContentCard;
