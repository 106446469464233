import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  veryLightPink,
  greenishcyan,
  breakpoints,
} from '../../../css-utilities';

const TabItem = ({ id, name, activeTab, setActiveTab }) => {
  const handleSetActiveTabs = useCallback(() => {
    setActiveTab(id);
  }, [id]);

  return (
    <>
      <button
        className={id === activeTab ? 'button active' : 'button'}
        key={name}
        onClick={handleSetActiveTabs}
        title={name}
      >
        {name}
      </button>
      <span className="divider" />

      <style jsx>
        {`
          .button {
            appearance: none;
            border: none;
            background-color: transparent;
            cursor: pointer;

            position: relative;
            text-align: left;
            padding: 10px 30px;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            color: #000000;
          }

          .button.active {
            font-weight: bold;
          }

          .button:first-child {
            padding-left: 0;
          }

          /* divider between tab items */
          .divider {
            display: block;
            top: -5px;
            right: 0;
            width: 1px;
            height: 40px;
            background: ${veryLightPink};
          }
          .divider:last-child {
            display: none;
          }

          .button:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            bottom: 3px;
            left: 30px;
            width: 0;
            height: 3px;
            background-color: ${greenishcyan};
            border-radius: 5px;
            opacity: 0;
            transition: 0.2s all ease;
            transform: scaleX(0);
            transform-origin: left;
          }

          /* line under text */
          .button.active:before {
            opacity: 1;
            width: calc(90% - 50px);
            transform: scaleX(1);
          }
          .button:first-child:before {
            left: 0;
            width: calc(90% - 50px);
            transform: scaleX(1);
          }

          /* make sure the buttons don't move sideways when toggeling active state (changing font weight) */
          .button:after {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }

          @media (min-width: ${breakpoints.tablet}) {
            .button {
              padding: 0 50px;
              font-size: 20px;
            }

            .button:before {
              left: 50px;
              width: calc(90% - 100px);
            }

            .button.active:before {
              width: calc(90% - 100px);
              transform: scaleX(1);
            }

            .button:first-child:before {
              left: 0;
              width: calc(90% - 50px);
            }

            .button:hover:before,
            .button:focus:before {
              opacity: 0.6;
              width: calc(90% - 100px);
              transform: scaleX(1);
            }

            .button:first-child:hover:before,
            .button:first-child:focus:before {
              opacity: 0.6;
              width: calc(90% - 50px);
              transform: scaleX(1);
            }
          }
        `}
      </style>
    </>
  );
};

TabItem.propTypes = {
  activeTab: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default TabItem;
