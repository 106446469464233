import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../Markdown';

import { breakpoints } from '../../css-utilities';
import Column from '../Column';

const CoursePlanCard = ({ coursePlanIntro, coursePlan }) => {
  const renderCoursePlan = coursePlan.map(({ text, id }) => (
    <div key={id}>
      <Markdown source={text} />
      <style jsx>
        {`
          div {
            padding: 0 20px 20px;
            background: #fff;
          }
        `}
      </style>
    </div>
  ));

  return (
    <>
      <Column width="70">
        <Markdown source={coursePlanIntro} />
      </Column>

      <section className="course-plan__items" style={{ width: '100%' }}>
        {renderCoursePlan}
      </section>

      <style jsx>
        {`
          div {
            height: 97%;
            background: white;
            margin: 10px 1%;
            padding: 20px;
          }

          .course-plan__items {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
          }
          @media (min-width: ${breakpoints.desktopSM}) {
            .course-plan__items {
              grid-template-columns: 1fr 1fr;
              grid-gap: 30px;
            }
          }
        `}
      </style>
    </>
  );
};

CoursePlanCard.propTypes = {
  coursePlan: PropTypes.array.isRequired,
  coursePlanIntro: PropTypes.string.isRequired,
};

export default CoursePlanCard;
