import React from 'react';

import CourseOccasion from './Occasion';

const CourseOccasionsWrapper = ({ course, occasions }) => {
  return occasions.map(occasion => (
    <CourseOccasion course={course} key={occasion.id} occasion={occasion} />
  ));
};

export default CourseOccasionsWrapper;
