import React from 'react';

const useClickedOutside = (refNode, fn) => {
  const detectClickOutside = event => {
    const didClickOutside = !refNode.current.contains(event.target);
    if (didClickOutside) {
      fn(event);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', detectClickOutside);
    return () => document.removeEventListener('click', detectClickOutside);
  }, []);
};

export default useClickedOutside;
