import React from 'react';
import PropTypes from 'prop-types';

import { SectionFullWidth } from '../../Section';
import CourseLeader from '../../CourseLeader';
import { veryLightBluishGray } from '../../../css-utilities';

const CourseLeadersSection = ({ courseLeaders }) => {
  const renderCourseLeaders = courseLeaders.map(courseLeader => (
    <CourseLeader key={courseLeader.id} {...courseLeader} />
  ));

  return renderCourseLeaders.length ? (
    <SectionFullWidth
      background={veryLightBluishGray}
      className="skew--top-left skew--bottom-left"
      fullWidth
    >
      <h3>Kursledare</h3>
      <div className="course-leaders__wrapper">{renderCourseLeaders}</div>

      <style jsx>
        {`
          h3,
          .course-leaders__wrapper {
            grid-column: 2 / 4;
          }
          .course-leaders__wrapper {
            margin-bottom: 50px;
          }
        `}
      </style>
    </SectionFullWidth>
  ) : null;
};

CourseLeadersSection.propTypes = {
  courseLeaders: PropTypes.array.isRequired,
};

export default CourseLeadersSection;
